<template>
  <svg
    width="10"
    height="17"
    viewBox="0 0 10 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.668637 16.5312C0.368683 16.2311 0.200179 15.8242 0.200179 15.4C0.200179 14.9757 0.368683 14.5688 0.668637 14.2688L5.93744 8.99998L0.668637 3.73118C0.51582 3.58358 0.393929 3.40703 0.310074 3.21182C0.22622 3.01662 0.182082 2.80666 0.180236 2.59422C0.178389 2.38177 0.218872 2.17108 0.299321 1.97445C0.379771 1.77781 0.498576 1.59917 0.648804 1.44894C0.799032 1.29872 0.977676 1.17991 1.17431 1.09946C1.37094 1.01901 1.58163 0.978527 1.79408 0.980373C2.00653 0.98222 2.21648 1.02636 2.41168 1.11021C2.60689 1.19407 2.78344 1.31596 2.93104 1.46878L9.33104 7.86877C9.63099 8.16882 9.79949 8.57571 9.79949 8.99998C9.79949 9.42424 9.63099 9.83113 9.33104 10.1312L2.93104 16.5312C2.63099 16.8311 2.2241 16.9996 1.79984 16.9996C1.37557 16.9996 0.968682 16.8311 0.668637 16.5312Z"
      fill="currentColor"
    />
  </svg>
</template>
<script>
import { Vue } from "vue-class-component";

export default class ChevronRight extends Vue {}
</script>
